/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import * as React from 'react';
import { graphql, navigate } from 'gatsby';
import { useLocation } from '@reach/router';

// Lib UI components
import { Container, Row, Col, Visible } from 'react-grid-system';

// Local UI components
import Seo from '../shared/Seo';
import LoginSection from '../page-components/Login/LoginSection';
import ImageTextOverlay from '../shared/UIKit/ImageTextOverlay';
//
// Helpers & Utils
import { isLoggedIn, connexionRoutes } from '../shared/Services/auth';

// Style
import '../page-styles/login.scss';

/* -------------------------------------------------------------------------- */
/*                                    Page                                    */
/* -------------------------------------------------------------------------- */

function Login() {
  /* ********************************** HOOKS ********************************* */
  const { pathname } = useLocation();
  const splitPathname = pathname.split('/').filter((value) => value.length > 0);
  const route = splitPathname[splitPathname.length - 1];

  function protectRoutes() {
    if (isLoggedIn()) {
      if (connexionRoutes.includes(route)) {
        navigate('/');
      }
    }
  }

  React.useEffect(() => {
    protectRoutes();
  }, []);

  // Localization

  /* ******************************** RENDERING ******************************* */
  return (
    <>
      <Seo title="Login" />
      <Container className="login" fluid>
        <Row>
          <Col
            xxl={8}
            xl={7}
            lg={7}
            md={6}
            sm={11}
            xs={11}
            className="login-form-container"
          >
            <LoginSection />
          </Col>
          <Visible xxl xl lg md>
            <ImageTextOverlay />
          </Visible>
        </Row>
      </Container>
    </>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["Login", "Common"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default Login;
