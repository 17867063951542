/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React, { useState } from 'react';
import { Link } from 'gatsby';
import { useTranslation } from 'react-i18next';

// UI lib comoponents
import { Row, Col } from 'react-grid-system';

// Local UI components
import InputField from '../../../shared/UIKit/InputField';
import FormButton from '../../../shared/UIKit/FormButton';
import Loader from '../../../shared/UIKit/Loader';

// Redux actions and selectors
// @TODO: If using Redux, import actions and selectors

// Helpers & utils
import { handleLogin } from '../../../shared/Services/auth';

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function LoginSection() {
  /* ********************************** HOOKS ********************************* */
  const [errorMessage, setErrorMessage] = useState();
  const [isLoading, setIsLoading] = useState(false);

  // localization
  const { t } = useTranslation();
  const loginLocale = t('login', { returnObjects: true });
  const { registerCta, title, email, password } = loginLocale;
  const { forgotPassword, errorMessages } = loginLocale;

  /* ***************************** LOCAL VARIABLES **************************** */

  /* ******************************** CALLBACKS ******************************* */

  function handleSubmit(e) {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const fieldValues = Object.fromEntries(formData.entries());

    handleLogin(
      fieldValues.email,
      fieldValues.password,
      setErrorMessage,
      setIsLoading,
    );

    return true;
  }

  /* ***************************** RENDER HELPERS ***************************** */

  /**
   * Renders subscribe Link
   * @returns {React.Element} The rendered subscribeLink
   */
  function GetSbuscribeLink() {
    return (
      <Row className="subscribe" align="center">
        <p>{registerCta.text}</p>
        <Link to={registerCta.link.url}>{registerCta.link.label}</Link>
      </Row>
    );
  }
  /**
   * Renders subscribe Link
   * @returns {React.Element} The rendered subscribeLink
   */
  function GetTitlePage() {
    return (
      <Col lg={11} md={12} align="left" justify="center">
        <h2>{title}</h2>
      </Col>
    );
  }
  /* ******************************** RENDERING ******************************* */

  return (
    <Row
      xl={7}
      lg={7}
      align="center"
      justify="center"
      direction="column"
      className="login-section"
    >
      {isLoading ? <Loader fullScreen /> : null}
      <GetSbuscribeLink />
      <Col
        lg={11}
        md={11}
        align="center"
        justify="between"
        className="form-container"
      >
        <GetTitlePage />

        <form onSubmit={handleSubmit}>
          <Col lg={11} md={11} align="left" style={{ marginBottom: '10px' }}>
            <InputField
              name={email.name}
              type={email.type}
              required
              label={email.label}
            />
          </Col>
          <Row align="center" justify="end">
            <Col lg={3} md={3} style={{ margin: '2rem' }}>
              <Link to={forgotPassword.url}>{forgotPassword.label}</Link>
            </Col>
          </Row>
          <Col lg={11} md={11} align="left">
            <InputField
              name={password.name}
              type={password.type}
              required
              label={password.label}
            />
            {errorMessage?.length > 0 ? (
              <div className="invalid-creds">{errorMessages[errorMessage]}</div>
            ) : null}
          </Col>
          <Row align="center" justify="end">
            <Col lg={3} md={3} style={{ margin: '2rem' }}>
              <FormButton text="Se connecter" />
            </Col>
          </Row>
        </form>
      </Col>
    </Row>
  );
}

LoginSection.propTypes = {};

LoginSection.defaultProps = {};

export default LoginSection;
